import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import LogoIcon from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import CalendarIcon from "../../assets/images/calendar.svg";
import ClockIcon from "../../assets/images/Clock.svg";
import BookTourPopup from "../BookTourPopup";
import PurchasePopup from "../PurchasePopup";

const NavItem = ({ title, options, id, onNavItemClick }) => {
  const location = window.location.pathname;
  const navigate = useNavigate();

  return (
    <div className={`navItem`}>
      <span onClick={() => onNavItemClick(id)}>{title}</span>
      {options?.length > 0 && (
        <>
          <div className="navItem_menu">
            {options.map((opt, i) => (
              <div
                className={`${location === opt.path ? "navItem_active" : ""}`}
                onClick={() => navigate(opt.path)}
                key={i}
              >
                {opt.option}{" "}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const HamburgerIcon = ({ active, handleMenuClick }) => {
  return (
    <div
      onClick={handleMenuClick}
      className={`hamburger ${active ? "is-active" : ""}`}
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  );
};

const NavMain = ({ onNavItemClick, setMenuActive }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="navbar_items">
        <NavItem title="About" id="about" onNavItemClick={onNavItemClick} />
        <NavItem
          title="Labs & Resources"
          id="services"
          onNavItemClick={onNavItemClick}
        />
        {/* <NavItem
          title="Testimonials"
          id="testimonialsSection"
          onNavItemClick={onNavItemClick}
        /> */}
        <NavItem title="Classes" id="classes" onNavItemClick={onNavItemClick} />
        <div
          className={`navItem`}
          onClick={() => {
            navigate("/camps");
            setMenuActive(false);
            document.body.style.overflow = "auto";
            window.scrollTo(0, 0);
          }}
        >
          <span>Camps</span>
        </div>
        {/* <NavItem
          title="Memberships"
          id="membership"
          onNavItemClick={onNavItemClick}
        /> */}
        <NavItem title="Parties" id="party" onNavItemClick={onNavItemClick} />
        <NavItem title="Donate" id="donation" onNavItemClick={onNavItemClick} />
        {/* <NavItem
          title="Our team"
          id="team"
          onNavItemClick={onNavItemClick}
        /> */}
        <NavItem title="Contact" id="contact" onNavItemClick={onNavItemClick} />
      </div>
    </>
  );
};

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const [bookTourPopup, setBookTourPopup] = useState(false);

  document.addEventListener("scroll", () => {
    if (window.scrollY > 80) {
      setIsFixedHeader(true);
    } else {
      setIsFixedHeader(false);
    }
  });

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuActive]);

  const onNavItemClick = (id) => {
    if (menuActive) {
      setMenuActive(false);
      setTimeout(
        () =>
          document?.getElementById(id).scrollIntoView({ behavior: "smooth" }),
        500
      );
    } else {
      document?.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <PurchasePopup
        isOpen={bookTourPopup}
        setIsOpen={setBookTourPopup}
        isTour
      />
      {/* <BookTourPopup isOpen={bookTourPopup} setIsOpen={setBookTourPopup} /> */}
      <nav
        className={`navbar ${isFixedHeader ? "navbarFixed" : ""} ${
          menuActive ? "navbar_active" : ""
        }`}
      >
        <div className="container">
          <img
            onClick={() => onNavItemClick("welcomeSection")}
            className="navbar_logo"
            src={LogoIcon}
            alt="logo"
          />
          <NavMain
            onNavItemClick={onNavItemClick}
            setMenuActive={setMenuActive}
          />
          <Button
            onClick={() => setBookTourPopup(true)}
            parentClass="navbar_button"
          >
            Visit
          </Button>
          <div className="navbar_hamburgerContainer">
            {!menuActive && (
              <div
                className="navbar_calendarButton"
                onClick={() => setBookTourPopup(true)}
              >
                <img
                  src={CalendarIcon}
                  alt="calendar"
                  className="navbar_calendarButtonImg1"
                />
                <img
                  src={ClockIcon}
                  alt="clock"
                  className="navbar_calendarButtonImg2"
                />
              </div>
            )}
            <HamburgerIcon
              active={menuActive}
              handleMenuClick={() => setMenuActive(!menuActive)}
            />
          </div>
        </div>
        {menuActive && (
          <div className="navbar_mobileMenu">
            <NavMain
              onNavItemClick={onNavItemClick}
              setMenuActive={setMenuActive}
            />
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
